<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog" v-if="savable">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-6">
      <v-form v-model="formValid" ref="form">
        <v-row class="dense-wrapper">
          <v-col cols="12" class="pt-0">
            <v-switch
                class="mt-1"
                v-model="all_flats"
                hide-details
                label="Усі абоненти організації"
                color="secondary"/>
          </v-col>
          <v-col cols="12">
            <AddressElementSelect
                label="Об’єднана громада" v-model="amalgamated_hromada_id"
                :parent_id="amalgamated_hromada_id" :use_parent_id="true"
                filled select_type="amalgamated_hromada" :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect v-model="city_id"
                                  label="Населений пункт" filled
                                  select_type="city" :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect :parent_id="city_id" v-model="streets"
                                  label="Вулиця(-ки)" multiple filled
                                  select_type="street" use_parent_id
                                  :disabled="!city_id"
            />
          </v-col>
          <v-col cols="12">
            <AddressElementSelect
                label="Район міста" v-model="city_area_id"
                :parent_id="city_id" :use_parent_id="true"
                filled select_type="city-area" :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12" v-if="city_area_id">
            <AddressElementSelect
                label="Вулиця" v-model="streets" multiple
                :parent_id="city_area_id" :use_parent_id="true"
                filled select_type="street-with-city-area" :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12" v-else>
            <AddressElementSelect
                label="Вулиця(-ці)" v-model="streets" multiple
                :parent_id="city_id" :use_parent_id="true"
                filled select_type="street" :disabled="all_flats"
            />
          </v-col>
          <v-col cols="12">
            <v-select :items="services_"
                      filled label="Послуга (для закриття)"
                      hide-details
                      v-model="service_id_to_close"
                      color="grey"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id_to_close ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12">
            <v-select :items="services_"
                      filled label="Послуга (для перенесення)"
                      hide-details
                      v-model="service_id_to_swap"
                      color="grey"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id_to_swap ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12">
            <AC_TariffGroup :value="tariff_group_type_id" @autocompleteChange="autocompleteChange"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="debt_type_select"
                      filled label="Тип періоду заборгованості"
                      hide-details
                      v-model="debt_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="debt_type ? '' : 'req-star'"
                      color="grey"
                      class="mr-2"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="debt_sum_type_select"
                      filled label="Тип заборгованості"
                      hide-details
                      v-model="debt_sum_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="debt_sum_type ? '' : 'req-star'"
                      color="grey"
                      class="mr-2"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
                v-model="comment" filled
                label="Коментар"
                color="grey" hide-details
                auto-grow rows="1" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createFlatIndicator" :loading="getModalLoading"
             class="button-accept" v-if="savable">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {
  SELECT_SERVICES_WITHOUT_ALL_SERVICE
} from "@/store/actions/services";
import closeSwapDebtAPI from "@/utils/axios/close_swap_debt"

import ModalComponentMixin from "@/mixins/modal_component";
import {START_MODAL_LOADING, STOP_MODAL_LOADING} from "@/store/actions/loading";

const modalDeleteId = 'close_swap_debt_legacy_modal_delete'

export default {
  name: "HWP_Close_Swap_Debt_Legacy",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    AC_TariffGroup: () => import("@/components/autocomplite/AC_TariffGroup"),
  },
  data() {
    return {
      navigation: false,
      all_flats: this.item.all_flats || false,
      debt_type: this.item.debt_type || null,
      debt_sum_type: this.item.debt_sum_type || null,
      amalgamated_hromada_id: this.item.amalgamated_hromada_id || null,
      city_id: this.item.city_id || null,
      city_area_id: this.item.city_area_id || null,
      streets: this.item.streets || [],
      buildings: this.item.buildings || [],
      checker_id: this.item.checker_id || null,
      service_id_to_close: this.item.service_id_to_close || null,
      service_id_to_swap: this.item.service_id_to_swap || null,
      comment: this.item.comment || '',
      month: this.item.month || null,
      tariff_group_type_id: this.item.tariff_group_type_id || null,
      debt_type_select: [
        { text: 'Борг на поч. місяця', value: 'debt_on_start_month' },
        { text: 'Борг на кін. місяця', value: 'debt_on_end_month' },
        { text: 'Борг на поч. місяця мінус оплата', value: 'debt_on_start_month_exclude_pay' }
      ],
      debt_sum_type_select: [
        { text: 'Від’ємні суми', value: 'minus' },
        { text: 'Додатні суми', value: 'plus' },
        { text: 'Усі суми', value: 'all' }
      ],
      main_contract: this.item.main_contract || false,
    }
  },
  computed: {
    ...mapGetters({
      services_: 'getServicesSelectWithoutAll',
      current_month: 'getCurrentMonth',
    }),
    savable() {
      if (!this.month) {
        return false
      }
      return this.current_month === this.month;

    }
  },
  methods: {
    ...mapActions({
      fetchServices_: SELECT_SERVICES_WITHOUT_ALL_SERVICE,
    }),
    documentReady() {
      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          return false
        }
      }
      return true
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.debt_type = this.item.debt_type || null
      this.debt_sum_type = this.item.debt_sum_type || null
      this.all_flats = this.item.all_flats || false
      this.amalgamated_hromada_id = this.item.amalgamated_hromada_id || null
      this.city_id = this.item.city_id || null
      this.city_area_id = this.item.city_area_id || null
      this.streets = this.item.streets || []
      this.buildings = this.item.buildings || []
      this.checker_id = this.item.checker_id || null
      this.service_id_to_close = this.item.service_id_to_close || null
      this.service_id_to_swap = this.item.service_id_to_swap || null
      this.tariff_group_type_id = this.item.tariff_group_type_id || null
      this.comment = this.item.comment || ''
      this.month = this.item.month || null
      this.main_contract = this.item.main_contract || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    autocompleteChange(payload) {
      this.tariff_group_type_id = payload.value || null
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення закриття/перенесення боргів`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createFlatIndicator() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false

        let text = 'Тип періоду заборгованості, тип заборгованості, послуга (для закриття), послуга (для переміщення) мають бути заповнені'

        this.$store.commit(ALERT_SHOW, {
          text: text,
          color: 'error lighten-1'
        })
        return
      }

      if (!this.documentReady()) {
        this.$store.commit(ALERT_SHOW, {
          text: 'Оберіть "усі квартири" або оберіть необхідні Вам параметри відбору (вулиці, будинки)',
          color: 'error lighten-1'
        })
        return;
      }

      const payload = {
        all_flats: this.all_flats,
        amalgamated_hromada_id: this.amalgamated_hromada_id || null,
        city_id: this.city_id || null,
        city_area_id: this.city_area_id || null,
        streets: this.streets ? this.streets : [],
        buildings: this.buildings ? this.buildings : [],
        checker_id: this.checker_id || null,
        service_id_to_close: this.service_id_to_close,
        service_id_to_swap: this.service_id_to_swap,
        comment: this.comment ? this.comment : null,
        month: this.current_month,
        tariff_group_type_id: this.tariff_group_type_id || null,
        main_contract: this.main_contract || false
      }

      this.$store.commit(START_MODAL_LOADING)

      if (this.isNew) {
        closeSwapDebtAPI.create_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.$emit('crud', Object.assign({}, data, {method: 'create'}))
              this.closeModal()
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      } else {
        payload['id'] = this.itemId
        closeSwapDebtAPI.update_legacy(payload)
          .then(response => response.data)
          .then(data => {
            if (data) {
              this.$emit('crud', Object.assign({}, data, {method: 'update'}))
              this.closeModal()
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
          .finally(() => {
            this.$store.commit(STOP_MODAL_LOADING)
          })
      }

    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.all_flats = payload.all_flats || false
              this.amalgamated_hromada_id = payload.amalgamated_hromada_id || null
              this.city_id = payload.city_id || null
              this.city_area_id = payload.city_area_id || null
              this.streets = payload.streets || []
              this.buildings = payload.buildings || []
              this.checker_id = payload.checker_id || null
              this.service_id_to_close = payload.service_id_to_close || null
              this.service_id_to_swap = payload.service_id_to_swap || null
              this.comment = payload.comment || ''
              this.month = payload.month || null
              this.tariff_group_type_id = payload.tariff_group_type_id || null
              this.main_contract = payload.main_contract || false

              if (this.isNew) {
                this.debt_type = 'debt_on_end_month'
                this.debt_sum_type = 'minus'
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  closeSwapDebtAPI.remove_legacy(this.itemId)
                  .then(response => response.data)
                  .then(data => {
                    if (data) {
                      this.$emit('crud', Object.assign({id: this.itemId}, {method: 'delete'}))
                      this.closeModal()
                    }
                  })
                  .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
                  })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
  }
}
</script>
